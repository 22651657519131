<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-clipboard"></i> User Importer</b-card-title>
			<b-card-sub-title>Imports user data in bulk</b-card-sub-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<div fluid class="px-2 mt-4">
				<b-form @submit.stop.prevent="saveOnDatabase" novalidate>
					<div v-show="!importOngoing && !importDone">
						<!-- User Form -->
						<b-card title="Upload Form">
							<b-row class="mt-2 mb-4 ml-1" no-gutters>
								<b-col sm="10" md="8" lg="6" class="mr-4">
									<b-form-group label="User Form"
										description="Please select a valid json or csv file for user import form format.">
										<b-form-file v-model="file" ref="fileinput" @change="onUploadForm($event)"
											:state="Boolean(file)" placeholder="Choose a JSON or CSV file"></b-form-file>
									</b-form-group>
									<label>
										Download JSON template
										<a :href="jsonTemplateUrl" download="UserImporter.json">
											here
										</a>
										and CSV template
										<a :href="csvTemplateUrl" download="UserImporter.csv">
											here.
										</a>
									</label>
								</b-col>
								<b-col sm="1">
									<b-button variant="primary" class="reset-button"
										@click="startAnotherImport()">Reset</b-button>
								</b-col>
							</b-row>
						</b-card>

						<!-- Content Preview -->
						<b-card v-if="!importOngoing && jsonData !== null" title="Content Preview"
							sub-title="Below is the overview of the content of the user import form you have selected">
							<br />
							<json-viewer :value="jsonData" />
							<br />
							<b-button variant="primary" @click="onImportData()">Proceed with Import</b-button>
						</b-card>
					</div>

					<div v-show="importOngoing || importDone">
						<b-row class="my-12">
							<b-col sm="12">
								<b-card title="Import Status" sub-title>
									<div v-if="importErrors.length > 0">
										<p class="card-text">{{ importResultLog }}</p>
										<ul>
											<li v-for="(item, index) in importErrors" :key="index">
												{{ item }}
											</li>
										</ul>
									</div>

									<div v-else>
										<p class="card-text my-4">
											{{ importStatusDisplay }}
										</p>
									</div>

									<span v-show="!importOngoing">
										<b-button variant="primary" @click="startAnotherImport()">
											Start Another Import
										</b-button>
									</span>
								</b-card>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</div>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { ImportUtil } from '@/utils/importUtil';

// API
import dataImporterApi from '@/api/dataImporterApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
let Papa = require('papaparse');
import _ from 'lodash';

export default {
	name: 'user-importer',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			file: null,

			importOngoing: false,
			importDone: false,
			hasError: false,
			importResultLog: '',
			importErrors: [],

			jsonData: null,
			jsonTemplateUrl: '../../assets/files/UserImporter.json',
			csvTemplateUrl: '../../assets/files/UserImporter.csv',

			allCompaniesObj: {},
			currUserId: this.$store.getters.loggedUser.id,
		};
	},
	computed: {
		importStatusDisplay() {
			let statusDisplay = '';

			if (this.importOngoing) {
				statusDisplay = 'Import In-Progress.';
			} else if (this.hasError) {
				statusDisplay = this.importResultLog;
			} else {
				statusDisplay = 'Import Successful! \n' + this.importResultLog;
			}
			return statusDisplay;
		},
		nameRegex() {
			return config.nameRegex;
		},
		contactNoRegex() {
			return config.contactNoRegex;
		},
		emailRegex() {
			return config.emailRegex;
		},
		employeeNoRegex() {
			return config.employeeNoRegex;
		},
		positionRegex() {
			return config.positionRegex;
		}
	},
	mounted() {
		setTimeout(() => {
			try {
				// Filter Access
				if (this.$store.getters.isViewer || this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				// Load initial data
				this.allCompaniesObj = { ...this.$store.getters.companies };

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		startAnotherImport() {
			this.importDone = false;
			this.importOngoing = false;

			// reset error status
			this.resetErrorStatus();

			// reset form fields
			this.$refs.fileinput.reset();
			this.file = null;
			this.jsonData = null;
		},
		resetErrorStatus() {
			this.hasError = false;
			this.importResultLog = '';
			this.importErrors = [];
		},

		onUploadForm(ev) {
			const file = ev.target.files[0];

			if (this.validateUploadForm(file)) {
				const reader = new FileReader();
				reader.onload = (e) => {
					let extension = file.name.split('.').pop().toLowerCase();
					if (extension === 'csv') {
						const { data } = Papa.parse(e.target.result, {
							header: true,
							skipEmptyLines: true,
							dynamicTyping: true,
							encoding: "UTF-8",
						});

						this.jsonData = this.formatJsonData(data);
					} else {
						this.jsonData = JSON.parse(e.target.result);
						this.jsonData = this.formatJsonData(this.jsonData.users);
					}
				};

				reader.readAsText(file);
			}
		},
		validateUploadForm(file) {
			let isValid = true;
			const fileTypes = ['csv', 'json'];
			const extension = file.name.split('.').pop().toLowerCase();
			const isAllowed = fileTypes.indexOf(extension) > -1;

			if (!file) {
				this.$toaster.warning('Please select a valid user form to proceed.');
				isValid = false;
				this.jsonData = null;
			} else if (!isAllowed) {
				this.$toaster.error('Invalid File Type: Please import a valid user form in JSON or CSV format.');
				isValid = false;
				this.jsonData = null;
			}

			return isValid;
		},
		formatJsonData(params) {
			let results = [];
			params.forEach((param) => {
				param = ImportUtil.trimWhiteSpaces(param);
				results.push(param);
			});

			return {
				users: results,
			};
		},

		async onImportData() {
			try {
				let usersArr = this.jsonData.users;

				if (usersArr.length === 0) {
					this.$toaster.warning('At least 1 user is required per import.');
					return;
				} else if (usersArr.length > 500) {
					this.$toaster.warning('Only maximum of 500 users is allowed per import.');
					return;
				} else if (ImportUtil.hasDuplicates(usersArr, 'emailAddress')) {
					let duplicates = ImportUtil.getDuplicates(usersArr, 'emailAddress');
					this.$toaster.warning(
						'Duplicates detected! Please address the following user(s): ' +
						JSON.stringify(duplicates)
					);
					return;
				} else if (ImportUtil.hasBlankColumnName(usersArr[0])) {
					this.$toaster.warning('Empty Column Name is not allowed. Please check your column names.');
					return;
				} else if (!this.validateDataImport(usersArr)) {
					return;
				}

				await this.saveOnDatabase(this.jsonData);
			} catch (_error) {
				this.$toaster.warning('The imported user data is invalid and cannot be processed');
			}
		},
		validateDataImport(users) {
			let isValid = true;
			let errors = [];

			let userTypes = ImportUtil.getTypes(config.userTypes);
			let companies = Object.values({ ...this.allCompaniesObj }).map(company => company.name);

			_.forEach(users, (item, key) => {
				let emailAddress = item.emailAddress;
				let index = key + 1;
				let errPrefix = (!emailAddress || emailAddress.length === 0) ? 'Item ' + index : 'User ' + emailAddress;

				if (!item.emailAddress || item.emailAddress.length === 0) {
					errors.push(errPrefix + ' has no email address. This is required.');
				} else if (!this.emailRegex.test(item.emailAddress)) {
					errors.push(errPrefix + ' email has invalid format.');
				}

				if (!item.firstName || item.firstName.length === 0) {
					errors.push(errPrefix + ' has no first name. This is required.');
				} else if (!this.nameRegex.test(item.firstName)) {
					errors.push(errPrefix + ' first name should only contain letters and numbers.');
				}

				if (item.middleName && item.middleName.length > 0 && !this.nameRegex.test(item.middleName)) {
					errors.push(errPrefix + ' middle name should only contain letters and numbers.');
				}

				if (!item.lastName || item.lastName.length === 0) {
					errors.push(errPrefix + ' has no last name. This is required.');
				} else if (!this.nameRegex.test(item.lastName)) {
					errors.push(errPrefix + ' last name should only contain letters and numbers.');
				}

				if (!item.position || item.position.length === 0) {
					errors.push(errPrefix + ' has no position. This is required.');
				} else if (!this.positionRegex.test(item.position)) {
					errors.push(errPrefix + ' position should only contain letters and numbers up to 60 characters only.');
				}

				if (item.employeeNo && item.employeeNo.length > 0 && !this.employeeNoRegex.test(item.employeeNo)) {
					errors.push(errPrefix + ' employee number should only contain numbers up to 12 digits only.');
				}

				if (item.contactNo && item.contactNo.length > 0 && !this.contactNoRegex.test(item.contactNo)) {
					errors.push(errPrefix + ' contact number, ' + item.contactNo + ', has invalid format. Valid format: +639xxxxxxxxx.');
				}

				// User Type
				if (!item.type || item.type.length === 0) {
					errors.push(errPrefix + ' has no type. This is required.');
				} else if (item.type && item.type.length !== 0 && !userTypes.includes(item.type)) {
					let errMsg = errPrefix + ' type, "' + item.type + '", is invalid. Valid types are: ';
					errMsg += userTypes.join() + '.';
					errors.push(errMsg);
				}

				// Company
				if (!item.company || item.company.length === 0) {
					errors.push(errPrefix + ' has no company. This is required.');
				} else if (item.company && item.company.length !== 0 && !companies.includes(item.company)) {
					errors.push(errPrefix + ' is associated to company, "' + item.company + '", that you don\'t have access to.');
				}
			});

			if (errors.length > 0) {
				isValid = false;

				this.hasError = true;
				this.importErrors = errors;
				this.importResultLog = 'The imported form has error(s).';
				this.importDone = true;
				this.importOngoing = false;
			}

			return isValid;
		},
		async saveOnDatabase(json) {
			try {
				// show loading indicator
				this.isLoading = true;

				this.importOngoing = true;
				this.importDone = false;

				let param = {
					currUserId: this.currUserId,
					currTimeStamp: DateUtil.getCurrentTimestamp(),
					users: json.users,
				};

				let { data } = await dataImporterApi.importUsers(param);

				this.hasError = !data.isSuccess;
				this.importErrors = data.errors;
				this.importResultLog = data.message;
				this.importDone = true;
				this.importOngoing = false;

				// update store
				let usersObj = data.users;
				this.$store.dispatch('updateAllUsers', usersObj);
			} catch (error) {
				this.hasError = true;
				this.importResultLog = error;
				this.importDone = true;
				this.importOngoing = false;
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
};
</script>
